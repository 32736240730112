


















































import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Row, Col, Image, Button, Dialog, Toast, List } from "vant";
import SelectCondition from "./SelectCondition.vue";
import ActivityItem from "./ActivityItem.vue";
import refreshToken from "@/api/oauth2/mods/token/refreshToken";
@Component({
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Mixin,
    ActivityItem,
    SelectCondition,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,

    [List.name]: List,
    [Button.name]: Button,
  },
})
export default class ActivityList extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public activityList!: Array<cms.ApplyActivityListDto>;
  @Prop({ default: false, type: Boolean }) public isAll!: boolean; //true:全部活动 false:已报名活动
  @Prop({ default: "", type: String }) public selectType!: string;
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public recommendActivityList!: Array<cms.ApplyActivityListDto>;
  @Prop({
    default: () => {
      return { loading: false, finished: true };
    },
    type: Object,
  })
  public recommendlist!: any;

  @Prop({ default: false, type: Boolean }) public tobePaidShow!: boolean;

  tobePaid = false; //是否为待支付
  public list = {
    loading: false,
    finished: true,
  };
  public page = {
    current: 1,
    size: 10,
  };
  // public recommendActivityList: Array<cms.ApplyActivityListDto> = [];
  @Watch("activityList")
  watchActivityList(value: Array<cms.ApplyActivityListDto>): void {
    // if (this.isAll && value.length == 0) {
    //   this.getRecommendActivityList(true);
    // }
    this.tobePaid = this.selectType == "4";
    // this.tobePaidShow;
  }
  select(form: {
    startTimeStamp: Date;
    endTimeStamp: Date;
    type: string;
  }): void {
    this.$emit("sendTimes", form);
  }
  changeChose(type: string): void {
    this.tobePaid = type == "4";
    this.$emit("changeButton", type);
  }
  enterDetail(activityId: string): void {
    this.$emit("enterDetail", activityId);
  }
  getRecommendActivityList(): void {
    this.$emit("getrecommend", false);
  }
  // 全部活动页面的——推荐活动
  // getRecommendActivityList(first: boolean): void {
  //   if (first) {
  //     this.page.current = 1;
  //   } else {
  //     this.page.current++;
  //   }
  //   if (this.isAll && this.activityList.length == 0) {
  //     this.$api.cmsApi.applyActivity.searchAllApplyActivityPager(
  //       {
  //         page: this.page.current,
  //         size: this.page.size,
  //       },
  //       ({ data }) => {
  //         if (this.page.current === 1) {
  //           this.recommendActivityList = [];
  //           this.recommendActivityList = data.records!;
  //         } else {
  //           if (
  //             this.recommendActivityList &&
  //             this.recommendActivityList.length
  //           ) {
  //             this.recommendActivityList = this.recommendActivityList.concat(
  //               data.records!
  //             );
  //           }
  //         }
  //         this.list.loading = false;
  //         this.list.finished = data.current! >= data.pages!;
  //       }
  //     );
  //   }
  // }
}
