







































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import navbar from "@/components/NavBar.vue";
import { Row, Tab, Tabs, Button, List, NavBar, Image, Sticky } from "vant";
import ActivityList from "./Components/ActivityList.vue";
import { Route, NavigationGuardNext, RawLocation } from "vue-router";
@Component({
  name: "enterActivity",
  components: {
    Mixin,
    navbar,
    ActivityList,
    [Row.name]: Row,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Button.name]: Button,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    [Sticky.name]: Sticky,
  },
})
export default class Club extends Mixins(Mixin) {
  public AllActivityList: Array<cms.ApplyActivityListDto> = [];
  public EnterActivityList: Array<cms.ApplyActivityListDto> = [];
  public isAll = true;
  public startTimeStamp: number | null = null;
  public endTimeStamp: number | null = null;
  public paramsStartTime = "";
  public paramsEndTime = "";
  public list = {
    loading: false,
    finished: true,
  };
  public page = {
    current: 1,
    size: 10,
  };
  public isChosen: any = "";
  isBackFrom = "";
  scrollTop = 0;
  mark = false;
  public activityTab = "all";
  public recommendActivityList: Array<cms.ApplyActivityListDto> = [];
  public recommendlist = {
    loading: false,
    finished: true,
  };
  public tobePaidShow = false;

  activated(): void {
    this.scrollTo(String(this.scrollTop));
    this.initData();
  }

  // @Watch("$route")
  // changeRoute(to: Route): void {
  //   console.log(to.query + "3");
  //   this.initData();
  // }
  created(): void {
    this.initData();
  }
  initData(): void {
    this.isAll = true;
    this.activityTab = "all";
    let query = this.$route.query;
    if (query.isAll && query.isAll == "false") {
      this.isAll = false;
      this.activityTab = "myActive";
    }
    this.isChosen = query.selectType || "";
    // let cityName = String(this.$route.query.cityName);
    // let cityCode = String(this.$route.query.cityCode).match(/code/g)
    //   ? String(this.$route.query.cityCode).split("code")[0]
    //   : String(this.$route.query.cityCode);
    // if (cityName != "undefined" && cityCode != "undefined") {
    //   this.LocationModule.SET_city_PERSIST({ cityName, cityCode });
    //   this.LocationModule.SET_isChangeCity_PERSIST(true);
    // }
    this.updateData();
  }
  updateData(): void {
    if (this.isAll) {
      this.AllActivityList = [];
      this.EnterActivityList = [];
      this.getActList(true);
    } else {
      this.AllActivityList = [];
      this.AllActivityList = [];
      this.getEnterAct(true);
    }
  }

  sendTimes(params: {
    startTimeStamp: 0;
    endTimeStamp: 0;
    type: string;
  }): void {
    this.startTimeStamp = params.startTimeStamp;
    this.endTimeStamp = params.endTimeStamp;
    this.paramsStartTime =
      this.startTimeStamp === 0 ||
      this.startTimeStamp === null ||
      this.endTimeStamp === undefined
        ? ""
        : String(this.startTimeStamp);
    this.paramsEndTime =
      this.endTimeStamp === 0 ||
      this.endTimeStamp === null ||
      this.endTimeStamp === undefined
        ? ""
        : String(this.endTimeStamp);
    this.getActList(true);
  }
  changeButton(type: string): void {
    this.EnterActivityList = [];
    this.AllActivityList = [];
    this.isChosen = type;
    // if (type === "entered") {
    //   this.getRosterList(true);
    // } else {
    this.getEnterAct(true);
    // }
  }
  /* 活动报名列表*/
  getActList(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    if (this.isAll) {
      this.$api.cmsApi.applyActivity.searchApplyActivityPager(
        {
          page: this.page.current,
          size: this.page.size,
          activityStartTime: this.paramsStartTime,
          activityName: "",
          activityEndTime: this.paramsEndTime,
          cityCode:
            this.LocationModule.cityCode == "undefined"
              ? ""
              : this.LocationModule.cityCode,
        },
        ({ data }) => {
          this.mark = true;
          console.log(this.page.current);
          if (this.page.current === 1) {
            this.AllActivityList = [];
            this.AllActivityList = data.records!;
          } else {
            if (this.AllActivityList && this.AllActivityList.length) {
              this.AllActivityList = this.AllActivityList.concat(data.records!);
            }
          }
          this.list.loading = false;
          this.list.finished = data.current! >= data.pages!;
          if (this.AllActivityList.length == 0) {
            this.getRecommendActivityList(true);
          }
        }
      );
    }
  }
  // 全部活动页面的——推荐活动
  getRecommendActivityList(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    if (this.isAll && this.AllActivityList.length == 0) {
      this.$api.cmsApi.applyActivity.searchAllApplyActivityPager(
        {
          page: this.page.current,
          size: this.page.size,
        },
        ({ data }) => {
          if (this.page.current === 1) {
            this.recommendActivityList = [];
            this.recommendActivityList = data.records!;
          } else {
            if (
              this.recommendActivityList &&
              this.recommendActivityList.length
            ) {
              this.recommendActivityList = this.recommendActivityList.concat(
                data.records!
              );
            }
          }
          this.recommendlist.loading = false;
          this.recommendlist.finished = data.current! >= data.pages!;
        }
      );
    }
  }
  getEnterAct(first: boolean): void {
    if (!this.UserModule.isLogin) {
      this.jsBridge("needLogin", "", () => {
        this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({
          router: this.$router,
          newPage: true,
          goBackAfterLogin: true,
        });
      });
    } else {
      if (first) {
        this.page.current = 1;
      } else {
        this.page.current++;
      }
      this.$api.cmsApi.applyActivity.getApplyActivitiesList(
        {
          page: this.page.current,
          size: this.page.size,
          applyStatus: this.isChosen,
          // activityStartTime: this.paramsStartTime,
          // activityName: "",
          // activityEndTime: this.paramsEndTime,
          // cityCode: this.LocationModule.cityCode,
        },
        ({ data }) => {
          if (this.page.current === 1) {
            this.EnterActivityList = data.records![0].applyActivityList!;
          } else {
            this.EnterActivityList = this.EnterActivityList.concat(
              data.records![0].applyActivityList!
            );
          }
          this.tobePaidShow = data.records![0].pendPayment!; // 是否有待支付的数据
          this.list.loading = false;
          this.list.finished = data.current! >= data.pages!;
        }
      );
    }
  }

  /**
   * 获取活动已入选名单
   * */
  getRosterList(first: boolean): void {
    if (first) {
      this.page.current = 1;
    } else {
      this.page.current++;
    }
    this.$api.cmsApi.applyActivity.listApplyActivityApplied(
      {
        isChosen: this.isChosen,
        page: this.page.current,
        size: this.page.size,
        // activityStartTime: this.paramsStartTime,
        // activityName: "",
        // activityEndTime: this.paramsEndTime,
        // cityCode: this.LocationModule.cityCode,
      },
      ({ data }) => {
        if (this.page.current === 1) {
          this.EnterActivityList = data.records!;
        } else {
          this.EnterActivityList = this.EnterActivityList.concat(data.records!);
        }
        this.list.loading = false;
        this.list.finished = data.current! >= data.pages!;
      }
    );
  }

  changeTab(name: number, title: string): void {
    if (title === "我的报名") {
      this.AllActivityList = [];
      this.isAll = false;

      // if (this.isChosen === "0") {
      this.getEnterAct(true);
      // } else {
      //   this.getRosterList(true);
      // }
    } else {
      this.EnterActivityList = [];
      this.isAll = true;
      this.getActList(true);
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm: any) => {
      console.log(to);
      if (from.path == "/selectcity") {
        delete to.query.isAll;
        delete to.query.selectType;
        //   delete to.query.cityName;
        //   delete to.query.cityCode;
        next({
          path: to.path,
          query: to.query,
          replace: true,
        });
        // vm.replacActive({
        //   path: to.path,
        //   query: to.query,
        //   replace: true,
        // });
      }
      let isBackFrom = (vm as Club).isBackFrom;
      if (isBackFrom) {
        (vm as Club).isBackFrom = "";
      }
      if (!isBackFrom || isBackFrom != from.fullPath) {
        /**
         * isBackFrom 为空说明不是从 Home 出去的或者不需要保持位置的
         * isBackFrom != from.fullPath 表示是从 Home 出去的，但是不是从出去的第一个页面回来的
         */
        (vm as Club).refreshPage(); // 刷新分页数据
      }
    });
  }
  enterDetail(id: string): void {
    this.scrollTop =
      document.getElementsByClassName("van-tabs__content")[0].scrollTop;
    let path = "";
    path = `/enter-activity-detail?id=${id}`;
    this.isBackFrom = path;
    this.$router.push(path);
  }
  scrollTo(val: string): void {
    this.$nextTick(() => {
      if (this.scrollTop) {
        if (document.getElementsByClassName("van-tabs__content")[0]) {
          document.getElementsByClassName("van-tabs__content")[0].scrollTop =
            Number(val);
        }
      }
    });
  }
  replacActive(data: RawLocation): void {
    this.$router.replace(data);
    // this.$router.replace( );
  }
  goback(): void {
    // this.$router.go(-1);
    this.$router.replace("/mine");
  }
  refreshPage(): void {
    window.scroll(0, 0);
    this.updateData();
    this.$nextTick(() => {
      document.getElementsByClassName("van-tabs__content")[0].scrollTop = 0;
    });
  }
}
