





















































import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { Row, Col, Button, Icon, Popup, Image, Badge } from "vant";
import ScreenTime from "./ScreenTime.vue";
@Component({
  components: {
    ScreenTime,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [Badge.name]: Badge,
  },
})
export default class SelectCondition extends Mixins(Mixin) {
  @Prop({ default: false, type: Boolean }) public isAll!: boolean;
  @Prop({ default: false, type: Boolean }) public tobePaid!: boolean;
  @Prop({ default: "", type: String }) public selectType!: string;
  showSelector = false;
  registeredBtnList: any[] = [
    { id: "", name: "全部" },
    { id: "4", name: "待支付" },
    { id: "0", name: "待审核" },
    { id: "1", name: "已入选" },
    { id: "2", name: "未入选" },
  ];
  choseTypeBtnText = "时间";
  selectedTab = "";
  @Watch("isAll", { immediate: true, deep: true })
  changeIsAll(val: boolean): void {
    if (val) {
      this.selectedTab = "";
    } else {
      this.scrollToView();
    }
  }
  @Watch("selectType", { immediate: true, deep: true })
  changeselectType(val: string): void {
    if (val) {
      this.selectedTab = val;
    }
  }
  scrollToView(): void {
    this.$nextTick(() => {
      let dov: any = document.querySelector(".active");
      if (dov) {
        dov.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }
  /*
  时间筛选全部活动全部活动
  */
  getSelectTime(form: {
    startTimeStamp: Date;
    endTimeStamp: Date;
    type: string;
  }): void {
    switch (form.type) {
      case "all":
        this.choseTypeBtnText = "全部时间";
        break;
      case "week":
        this.choseTypeBtnText = "一周内";
        break;
      case "month":
        this.choseTypeBtnText = "一月内";
        break;
      case "weekend":
        this.choseTypeBtnText = "本周末";
        break;
      case "appoint":
        // this.choseTypeBtnText = "指定时间";
        this.choseTypeBtnText = this.formDate(
          String(new Date(form.startTimeStamp).getTime())
        );
        break;
    }
    this.$emit("select", form);
    this.showSelector = false;
  }
  /**
   * 已报名活动筛选*/
  changeBtn(type: string): void {
    this.selectedTab = type;
    this.$emit("changeChose", type);
    // this.scrollToView();
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    return `${y}-${m}-${d}`;
  }
}
