




































import { Mixin } from "@/core/mixins/mixin";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Row, Col, Button, Image, NavBar } from "vant";
import navbar from "@/components/NavBar.vue";
@Component({
  components: {
    navbar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Image.name]: Image,
    [NavBar.name]: NavBar,
  },
})
export default class EnterSuccess extends Mixins(Mixin) {
  public activityId = "";
  created(): void {
    if (
      this.ChannelModule.channel === "plat_ios" ||
      this.ChannelModule.channel === "plat_android"
    ) {
      this.jsBridge("isShowShare", "0");
    }
    window.scrollTo(0, 0);
    this.activityId = String(this.$route.query.activityId);
  }
  goback(): void {
    console.log("我的报名-全部活动");
    // 回退到 我的报名-全部活动
    this.$router.replace({
      path: "/enter-activity",
      query: {
        isAll: `${false}`,
        selectType: "",
      },
    });
  }
  back(): void {
    this.$router.go(-1);
  }
}
