var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ActDetail"},[(_vm.ChannelModule.channel === 'plat_h5')?_c('van-nav-bar',{staticClass:"navbar",attrs:{"title":"活动详情","left-arrow":""},on:{"click-left":function($event){return _vm.$router.go(-1)}},scopedSlots:_vm._u([(_vm.ChannelModule.channel !== 'plat_h5')?{key:"right",fn:function(){return [_c('van-image',{staticStyle:{"width":"0.6rem","display":"inline-block"},attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Public/share.png')}})]},proxy:true}:null],null,true)}):_vm._e(),_c('van-row',{staticClass:"container",style:(_vm.ChannelModule.channel === 'plat_h5'
        ? "padding-top:1.08rem"
        : "padding-top:0")},[_c('van-row',{staticClass:"head-img"},[_c('van-image',{staticClass:"act-image",attrs:{"fit":_vm.imageFit,"src":_vm.activityInfo.activityImageUrl}})],1),_c('van-row',{staticClass:"act-desc"},[_c('van-col',{staticClass:"act-name two-omit"},[_vm._v(_vm._s(_vm.activityInfo.activityName))]),_c('van-col',{staticClass:"act-enter-time"},[_vm._v("报名时间:"+_vm._s(_vm.timestampFormat(Number(_vm.activityInfo.applyStartTime)))+" - "+_vm._s(_vm.timestampFormat(Number(_vm.activityInfo.applyEndTime))))]),_c('van-col',{staticClass:"free"},[_vm._v(_vm._s(_vm.applyTypeArr(_vm.activityInfo)))])],1),_c('van-row',{staticClass:"timeAddr"},[_c('van-col',{staticClass:"left"},[_c('p',{staticClass:"address"},[_vm._v(_vm._s(_vm.activityInfo.siteName))]),_c('p',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.formDate(String(_vm.activityInfo.activityStartTime)))+" ")])]),_c('van-col',{staticClass:"right"},[_c('router-link',{staticStyle:{"width":"100%"},attrs:{"to":("/map?name=" + (_vm.activityInfo.siteName) + "&address=" + (_vm.activityInfo.activityAddress))}},[_c('van-image',{staticClass:"location",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Project/icon-addr@2x.png')}})],1)],1)],1),(
        _vm.activityInfo.theaterStatus === '1' &&
        _vm.ChannelModule.channel !== 'plat_h5'
      )?_c('div',{staticClass:"detail-focus"},[_c('div',{staticClass:"detail-focus-name"},[_c('van-col',{staticClass:"theaterUrl"},[_c('van-image',{attrs:{"fit":_vm.imageFit,"round":"","width":"100%","height":"100%","src":_vm.activityInfo.theaterUrl},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('van-image',{attrs:{"fit":_vm.imageFit,"round":"","width":"100%","height":"100%","src":require('@/assets/images/Public/shop.png')}})]},proxy:true}],null,false,1882107160)})],1),_c('span',{staticClass:"theater-name"},[_vm._v(" "+_vm._s(_vm.activityInfo.theaterName)+" ")])],1),_c('van-button',{staticClass:"focus-btn",class:_vm.activityInfo.theaterIsFocus ? 'focus' : '',on:{"click":_vm.changeFocus}},[_vm._v(" "+_vm._s(_vm.theaterFocusTxt ? "已关注" : "关注")+" ")])],1):_vm._e(),_c('van-row',{staticClass:"line"}),_c('van-row',{staticClass:"act-introduce"},[_c('h3',{staticClass:"title"},[_vm._v("活动介绍")]),_c('van-col',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.actDesc)}})],1)],1),_c('van-row',{staticClass:"bottom"},[_c('van-row',{staticClass:"btn-group"},[(
          _vm.activityInfo.applyIsCheck === '1' &&
          _vm.activityInfo.applyStatus === 'ACTIVITY_APPLY_STATUS_OVER'
        )?_c('van-button',{staticClass:"btn1",class:{
          'enter-btn':
            _vm.activityInfo.applyIsCheck === '1' &&
            _vm.activityInfo.applyStatus === 'ACTIVITY_APPLY_STATUS_OVER',
        },on:{"click":function($event){_vm.$router.push(
            ("/apply-selected-list?activityId=" + (String(_vm.activityId)))
          )}}},[_vm._v("查看用户入选名单"),_c('van-image',{staticStyle:{"width":"0.1rem","height":"0.15rem","margin-left":"0.1rem"},attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/EnterAct/more-icon@2x.png')}})],1):_vm._e(),_c('van-button',{staticClass:"normal-btn",class:{
          btn2:
            _vm.activityInfo.isActivityFull ||
            _vm.activityInfo.applyStatus === 'ACTIVITY_APPLY_STATUS_OVER' ||
            _vm.activityInfo.applyStatus === 'ACTIVITY_APPLY_STATUS_COMING' ||
            (_vm.activityInfo.crtMemberIsApplay === '1' &&
              _vm.activityInfo.crtMemberApplyStatus !== '3'),
        },attrs:{"disabled":_vm.activityInfo.isActivityFull ||
          _vm.activityInfo.applyStatus === 'ACTIVITY_APPLY_STATUS_OVER' ||
          _vm.activityInfo.applyStatus === 'ACTIVITY_APPLY_STATUS_COMING' ||
          (_vm.activityInfo.crtMemberIsApplay === '1' &&
            _vm.activityInfo.crtMemberApplyStatus !== '3')},on:{"click":function($event){_vm.ApplyAct(String(_vm.activityInfo.applyStatus))}}},[_vm._v(_vm._s(_vm.BtnName))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }